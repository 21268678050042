<template>
  <div></div>
</template>
<script>
var wx = require('weixin-js-sdk')
export default {
  data () {
    return {
      initState: 'N',
      share: { title: '', desc: '', link: '', img: '' }
    }
  },
  mounted () {
  },
  methods: {
    async init (modules, callback) {
      var path = window.location.href
      var app = window.sessionStorage.getItem(this.SESSION_APP)
      var jsapi = ''
      if (modules.indexOf('SHARE') >= 0) {
        jsapi += 'updateAppMessageShareData,updateTimelineShareData,'
      }
      if (modules.indexOf('LOCATION') >= 0) {
        jsapi += 'getLocation,'
      }
      var pd = { debug: 'false', jsapi: jsapi.substring(0, jsapi.length - 1), url: path, app: app }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/config/createWJsapiConfig', this.$qs.stringify(pd))
      if (res.status === '200') {
        wx.config(res.data)
        // wx.error(function (res) {
        // })
        wx.ready(function () {
          callback()
        })
      }
    },
    initShare (title, desc, link, img) {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var site = window.sessionStorage.getItem(this.SESSION_SITE)
      link = link + '&sourceType=RFE&source=RFE_WPP'

      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (site !== undefined && site !== null && site !== '') {
        link = link + '&site=' + site
      }
      if (user !== undefined && user !== null && user !== '') {
        link = link + '&referee=' + user
      }
      this.share.title = title
      this.share.desc = desc
      this.share.link = link
      this.share.img = img
      this.settingShare()
    },
    settingShare () {
      wx.updateAppMessageShareData({
        title: this.share.title, // 分享标题
        desc: this.share.desc, // 分享描述
        link: this.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.share.img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.updateTimelineShareData({
        title: this.share.title, // 分享标题
        link: this.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.share.img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    },
    defaultShare () {
      var app = window.sessionStorage.getItem(this.SESSION_APP)
      if (app === 'WPP_MOC') {
        var title = '人生有难题，就来人生帮'
        var desc = 'K12上学、学历提升、证书考培、工作推荐、职称考评、出国签证、出国留学、出国务工，专业团队，线下服务，帮您解决人生难题！'
        var link = 'https://bms.utp.ink/integrate/home?f=1'
        var logo = 'https://bms.utp.ink/static/logo.png'
        this.initShare(title, desc, link, logo)
      }
    },
    getLocation (callback) {
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed // 速度，以米/每秒计
          var accuracy = res.accuracy // 位置精度
          callback(latitude, longitude, speed, accuracy)
        }
      })
    }
  }
}
</script>
