<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item @click="helper()">
        <img
          class="img"
          :src="require('../../../../assets/images/integrate/3.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid>
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.code)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="更多" @click="moreClassify('')">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-more"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="adv">
      <van-image
        class="img"
        :src="require('../../../../assets/images/hire/share.jpg')"
      ></van-image>
    </div>
    <van-row class="title">
      <van-col span="12" class="text"
        ><van-icon name="notes-o" /> 热门招聘</van-col
      >
      <van-col span="12" class="btn color-m"
        ><div @click="allSearch()">全部招聘 <van-icon name="arrow" /></div
      ></van-col>
    </van-row>
    <div class="task-list">
      <van-row
        class="task bdc-b1s-gray"
        v-for="task in taskList"
        :key="task.code"
        @click="detail(task.code)"
      >
        <van-col span="24" class="content">
          <van-row>
            <van-col span="24" class="name text-short"
              ><van-tag :color="COLOR_M" v-if="task.state === 'NML'"
                >进行中</van-tag
              ><van-tag :color="COLOR_S1" v-if="task.state === 'CMP'"
                >已招满</van-tag
              >
              {{ task.name }}</van-col
            >
          </van-row>
          <van-row>
            <van-col span="24" class="label">
              <span
                v-for="item in task.labels"
                :key="item.name"
                class="item bgc-gray"
                >{{ item.name }}</span
              >
            </van-col>
          </van-row>
          <van-row>
            <van-col span="14" class="time"
              ><van-icon name="underway-o" />&nbsp;{{ task.publishTime }}
            </van-col>
            <van-col span="10" class="salary fc-cyan">
              <span v-if="task.salaryMin === 0 && task.salaryMax === 0"
                >面议</span
              >
              <span v-if="task.salaryMin > 0">
                <span v-if="task.salaryMin > 1000">
                  {{ (task.salaryMin / 1000).toFixed(1) }}K
                </span>
                <span v-else>{{ task.salaryMin }}元</span>
              </span>
              <span v-if="task.salaryMax > 0"
                >-
                <span v-if="task.salaryMax > 1000"
                  >{{ (task.salaryMax / 1000).toFixed(1) }}K</span
                >
                <span v-else>{{ task.salaryMax }}元</span></span
              >/<span v-if="task.salaryType === 'YER'">年</span>
              <span v-if="task.salaryType === 'MON'">月</span>
              <span v-if="task.salaryType === 'DAY'">天</span>
              <span v-if="task.salaryType === 'FQC'">次</span>
              <span v-if="task.salaryType === 'TIM'">小时</span>
              <span v-if="task.salaryType === 'MET'">米</span>
              <span v-if="task.salaryType === 'SMT'">平米</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col :span="18" class="company">
              <span
                ><van-icon name="newspaper-o" />&nbsp;{{ task.supplierName }}
                <van-tag :color="COLOR_S2" v-if="task.supplierType === 'DRT'"
                  >直营</van-tag
                ></span
              ></van-col
            >
            <van-col span="6" class="amount"
              ><van-icon name="friends-o" />&nbsp;{{ task.count }}人</van-col
            >
          </van-row>
          <!-- <van-row>
            <van-col :span="18" class="address">
              <van-icon name="location-o" />&nbsp;{{ task.address }}</van-col
            >
            <van-col :span="6" class="distance"
              >距我{{ task.distance }} KM</van-col
            >
          </van-row> -->
          <van-row
            class="advantage"
            v-if="task.advantage !== undefined && task.advantage !== ''"
          >
            <van-col :span="24"
              ><van-tag :color="COLOR_S1">优势：</van-tag> &nbsp;<span
                class="text color-s1"
                >{{ task.advantage }}</span
              ></van-col
            >
          </van-row>
        </van-col>
      </van-row>
      <van-row v-if="taskList.length <= 0">
        <van-col span="24" class="no-record">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image>
        </van-col>
      </van-row>
    </div>
    <!-- </template>
    </van-list> -->
    <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="label-o"
            :color="COLOR_M"
            size="small"
            @click="seek()"
            >我要找工作</van-button
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <van-button
            class="btn"
            icon="user-o"
            :color="COLOR_S1"
            size="small"
            @click="publish()"
            >我要找工人</van-button
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <van-button
            class="btn"
            icon="phone-o"
            :color="COLOR_S2"
            size="small"
            @click="onlineChat()"
            >咨询客服</van-button
          >
        </van-col>
      </van-row>
    </div>
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="HIRE_CONSULT_ONLINE"
    />
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <LogVisit ref="logVisit" module-type="HRE" module-label="HRE_HOME_INDEX" />
    <JSSdk ref="jssdk" />
    <Tabbar active="home" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
