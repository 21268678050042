import { Search, DropdownMenu, DropdownItem, Image, Tag, NavBar, Popup, List, Icon, Grid, GridItem } from 'vant'
import Loading from '../../common/Loading.vue'
import JSSdk from '../../common/JSSdk.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import Sift from '../common/Sift.vue'
import Tabbar from '../common/Tabbar.vue'
import Call from '../../common/Call.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Loading: Loading,
        JSSdk: JSSdk,
        Login: Login,
        LogVisit: LogVisit,
        Clue: Clue,
        Sift: Sift,
        Call: Call,
        Tabbar: Tabbar,
        [Search.name]: Search,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Image.name]: Image,
        [Tag.name]: Tag,
        [NavBar.name]: NavBar,
        [Popup.name]: Popup,
        [List.name]: List,
        [Icon.name]: Icon,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem
    },
    data () {
        return {
            finished: false,
            loadingShow: false,
            activityCode: '',
            keyword: '',
            jobOptions: [{ text: '课程分类', value: '' }],
            jobCode: '',
            condition: { regionCode: '', sort: '' },
            classifyPage: { current: 0, size: 20, last: 0 },
            classifys: [],
            page: { current: 0, size: 10, last: 0 },
            taskList: []
        }
    },
    mounted () {
        document.title = '普工招聘'
        var query = this.$route.query
        if (query.keyword !== undefined && query.keyword !== null) {
            this.keyword = query.keyword
        }
        this.activityCode = query.activityCode
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.initCondition()
            this.retrieveDisplayClassifyPage()
            this.retrieveTaskPage()
            this.$refs.jssdk.init(['SHARE', 'LOCATION'], this.jssdkBack)
        },
        jssdkBack () {
            this.initShare()
            this.$refs.jssdk.getLocation(this.locationBack)
        },
        async retrieveDisplayClassifyPage () {
            var pd = { label: 'INDEX_HOT', current: this.classifyPage.current, size: this.classifyPage.size }
            var { data: res } = await this.$http.post(this.BMS_URL + '/hire/classify/retrieveDisplayClassifyPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
                this.page.last = res.page.last
            }
        },
        locationBack (latitude, longitude, speed, accuracy) {
            console.log(latitude + ',' + longitude)
        },
        initShare () {
            var title = '找普工好工作，就来人生帮'
            var desc = '建筑、家装、餐饮、服务、等全行业普工工作，真实可靠，方便快捷，让您快速上岗，无后顾之忧'
            var link = 'https://bms.utp.ink/mde/hire/home?1=1'
            var logo = 'https://bms.utp.ink/static/logo_lha.png'
            this.$refs.jssdk.initShare(title, desc, link, logo)
        },
        initCondition () {
        },
        siftCondition (condition) {
            this.taskList = []
            this.condition.instIndustry = condition.instIndustry
            this.condition.classify = condition.classify
            this.condition.region = condition.region
            this.condition.instNature = condition.instNature
            this.condition.instType = condition.instType
            this.condition.instScale = condition.instScale
            this.condition.type = condition.type
            this.page.current = 0
            this.page.last = 0
            this.finished = false
            this.retrieveTaskPage()
        },
        async retrieveTaskPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: '', current: this.page.current, size: this.page.size }
                const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveTaskPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, url: window.location.href }
            var content = '补岗招聘搜索'
            this.$refs.clue.createClue('RECRUIT_SEARCH_RECRUIT', dt, JSON.stringify(param), content)
        },
        search () {
            this.$router.push({ path: '/mde/hire/search', query: { keyword: this.keyword } })
        },
        allSearch () {
            this.$router.push({ path: '/mde/hire/search' })
        },
        classifySearch (code) {
            this.$router.push({ path: '/mde/hire/search', query: { classifyCode: code } })
        },
        moreClassify () {
            this.$router.push({ path: '/mde/hire/classify' })
        },
        publish () {
            this.$router.push({ path: '/mde/hire/publish' })
        },
        seek () {
            this.$router.push({ path: '/mde/hire/seek' })
        },
        detail (code) {
            this.$router.push({ path: '/mde/hire/detail', query: { taskCode: code } })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        back () {
            var url = window.sessionStorage.getItem(window.const.global.HIRE_HOME_INDEX_BACK)
            if (url !== undefined && url !== null && url !== '') {
                window.location.href = url
            } else {
                this.$dialog.confirm({
                    title: '提示',
                    message: '已到最普通招聘最顶层，是否跳转人生帮首页？'
                })
                    .then(() => {
                        // on confirm
                        this.$router.push({ path: '/integrate/home' })
                    })
            }
        }

    }
}
